.hover-opacity:hover {
  opacity: 0.4;
}

.hlTfmo { margin-bottom: 0px !important; }
.kJnpoy { border: 1px solid #DDD; padding: 7px 12px; }
label { margin: 0px };

@media screen and (min-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media screen and (max-width: 800px) {
  .not-in-mobile {
    display: none;
    visibility: hidden;
  }
}

.inline {
  display: inline;
}
.cian {
  color: #35BCBA;
}
strong { font-family: Gilroy-Bold;}
label {
  color: white;
  font-family: Gilroy;
}
.token-image {
  -webkit-box-shadow: 0px 7px 30px -7px #FFF, 5px 5px 15px 5px rgba(0,0,0,0.45); 
  box-shadow: 0px 7px 30px -7px #FFF, 5px 5px 15px 5px rgba(0,0,0,0.45);
  border-radius: 50%;
  background-color: white;
}
.main-content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.card-post__author-avatar {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ql-editor {
  color: white
}
.material-icons {
  font-size: 20px !important;
  top: 0 !important;
}
.card {
  background: #10192E
}

.growable {
  flex-wrap: wrap;
  max-width: unset;
  flex-grow: 1;  
}

.btn-primary {
  background-color: #35BCBA !important;
  border-color: #35BCBA !important;
}
.btn-primary:hover {
  background-color: rgb(42, 146, 144);
  border-color: #35BCBA;
}

.divider {
  background-color: #f2f2f2;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}